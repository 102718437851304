import React, { useMemo } from 'react';
import { classes, st } from './ButtonWrapper.st.css';
import { ButtonWrapperDataHooks } from '../dataHooks';
import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import type { Experiments } from '@wix/yoshi-flow-editor';
import {
  Button,
  ButtonSize as SIZE,
  Spinner,
  ButtonPriority,
} from 'wix-ui-tpa/cssVars';
import { useFormActions } from '../../../Hooks/useFormActions';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { ISettingsContextValue } from '@wix/tpa-settings';
import settingsParams from '../../../settingsParams';
import { ActionLabels } from '@wix/ambassador-services-catalog-server/http';
import {
  BookErrorType,
  CreateBookingErrorType,
  FormError,
} from '../../../../../types/errors';
import stylesParams from '../../../stylesParams';
import { getErrorByType } from '../../../../../utils/errors/errors';
import {
  CartFlow,
  PaymentOption,
  ReservedPaymentOptionIds,
  SummaryPaymentDetails,
} from '../../../../../types/types';
import { getContent } from '../../../../../utils/content/content';
import { SelectedPaymentOption } from '@wix/ambassador-bookings-gateway/types';
import {
  SectionMessage,
  SectionMessageType,
} from '../../SectionMessage/SectionMessage';
import { FormStatus } from '../../../../../types/form-state';
import { useFormComponentContext } from '../../../Hooks/useFormComponentContext';
import { ExperimentsConsts } from '../../../../../consts/experiments';

export interface ButtonWrapperProps {
  dataHook?: ButtonWrapperDataHooks;
  isCart?: boolean;
  errors: FormError[];
  isPendingApprovalFlow: boolean;
  actionLabels: ActionLabels;
  paymentTypes: SelectedPaymentOption[];
  status: FormStatus;
  selectedPaymentOption: PaymentOption;
  summaryPaymentDetails: SummaryPaymentDetails;
  cartFlow?: CartFlow;
  loading?: boolean;
}

export const ButtonWrapper: React.FC<ButtonWrapperProps> = ({
  dataHook,
  isCart,
  errors,
  isPendingApprovalFlow,
  actionLabels,
  paymentTypes,
  selectedPaymentOption,
  status,
  summaryPaymentDetails,
  cartFlow,
  loading,
}) => {
  const { t } = useTranslation();
  const { onSubmit, submitForm } = useFormActions();
  const { isBookingsOnEcom } = useFormComponentContext();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const settings = useSettings();

  const actionLabel = getActionLabel({
    selectedPaymentOption,
    isPendingApprovalFlow,
    actionLabels,
    paymentTypes,
    settings,
    summaryPaymentDetails,
    isBookingsOnEcom,
    isCart,
    experiments,
    cartFlow,
    t,
  });
  const styles = useStyles();
  const fontSize = styles.get(stylesParams.mainButtonFont).size!;

  const error = getErrorByType({
    errorType: isBookingsOnEcom ? CreateBookingErrorType : BookErrorType,
    errors,
  });

  const getErrorMessage = useMemo(() => {
    if (error) {
      switch (error.errorType) {
        case CreateBookingErrorType.SESSION_CAPACITY_EXCEEDED:
        case CreateBookingErrorType.SCHEDULE_CAPACITY_EXCEEDED as any:
          return t('app.server-errors.not-enough-spots-left');
        default:
          return t('app.server-errors.slot-not-available');
      }
    }
    return '';
  }, [error]);

  return (
    <div className={st(classes.root, { isMobile })}>
      <Button
        size={SIZE.medium}
        className={st(classes.bookButton, {
          primary: cartFlow !== CartFlow.CHECKOUT,
        })}
        data-hook={dataHook || ButtonWrapperDataHooks.ACTION_BUTTON}
        upgrade
        aria-pressed={loading}
        type="submit"
        priority={
          cartFlow === CartFlow.CHECKOUT
            ? ButtonPriority.basic
            : ButtonPriority.primary
        }
        fullWidth={settings.get(settingsParams.stretchButtonToFullWidth)}
        onClick={async () => {
          const submissionResponse = submitForm?.();
          if (submissionResponse) {
            if (isCart) {
              onSubmit(submissionResponse, cartFlow);
            } else {
              onSubmit(submissionResponse);
            }
          }
        }}
        disabled={status !== FormStatus.IDLE}
      >
        {loading ? (
          <div className={classes.spinnerWrapper}>
            <Spinner
              data-hook={ButtonWrapperDataHooks.SPINNER}
              className={classes.spinner}
              diameter={fontSize}
              isCentered
            />
          </div>
        ) : (
          t(actionLabel)
        )}
      </Button>
      <SectionMessage type={SectionMessageType.Error} text={getErrorMessage} />
    </div>
  );
};

const getActionLabel = ({
  selectedPaymentOption,
  isPendingApprovalFlow,
  actionLabels,
  paymentTypes,
  settings,
  summaryPaymentDetails,
  isBookingsOnEcom,
  isCart,
  experiments,
  cartFlow,
  t,
}: {
  selectedPaymentOption: PaymentOption;
  isPendingApprovalFlow: boolean;
  actionLabels: ActionLabels;
  paymentTypes: SelectedPaymentOption[];
  settings: ISettingsContextValue;
  summaryPaymentDetails: SummaryPaymentDetails;
  isBookingsOnEcom: boolean;
  isCart?: boolean;
  experiments?: Experiments;
  cartFlow?: CartFlow;
  t?: any;
}): string => {
  if (selectedPaymentOption?.id === ReservedPaymentOptionIds.BuyAPricingPlan) {
    return getContent({
      settings,
      settingsParam: settingsParams.chooseAPlanText,
      experiments,
    });
  } else if (isPendingApprovalFlow) {
    return isCart
      ? getContent({
          settings,
          settingsParam: settingsParams.addToCartText,
          experiments,
        })
      : settings.get(settingsParams.requestBookingButtonText) ||
          actionLabels.bookingRequestApprovalLabel!;
  } else if (isBookingsOnEcom) {
    if (isCart) {
      const isCartBoowNowSettingsEnabled = experiments?.enabled(
        ExperimentsConsts.CartBookNowSettings,
      );
      if (cartFlow === CartFlow.CHECKOUT) {
        return isCartBoowNowSettingsEnabled
          ? getContent({
              settings,
              settingsParam: settingsParams.bookNowButtonText,
              experiments,
            })
          : t('app.booking-details.summary.cta.book.label');
      }
      return getContent({
        settings,
        settingsParam: settingsParams.addToCartText,
        experiments,
      });
    } else if (
      selectedPaymentOption?.id !== ReservedPaymentOptionIds.SingleSession ||
      summaryPaymentDetails.payNow === 0
    ) {
      return (
        settings.get(settingsParams.offlinePaymentButtonText) ||
        actionLabels.offlinePaymentLabel!
      );
    } else {
      return (
        settings.get(settingsParams.onlinePaymentButtonText) ||
        actionLabels.onlinePaymentLabel!
      );
    }
  } else {
    if (
      canBePaidOnline(paymentTypes) &&
      selectedPaymentOption.id === ReservedPaymentOptionIds.SingleSession
    ) {
      return (
        settings.get(settingsParams.onlinePaymentButtonText) ||
        actionLabels.onlinePaymentLabel!
      );
    } else {
      return (
        settings.get(settingsParams.offlinePaymentButtonText) ||
        actionLabels.offlinePaymentLabel!
      );
    }
  }
};

const canBePaidOnline = (paymentTypes: SelectedPaymentOption[]) =>
  paymentTypes?.includes(SelectedPaymentOption.ONLINE);
