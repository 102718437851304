import React from 'react';
import { mapToArray } from '../../../../utils';
import {
  ServiceData,
  SlotService,
} from '../../../../utils/state/initialStateFactory';
import { ReservedPaymentOptionIds } from '../../../../types/types';
import { FormStatus } from '../../../../types/form-state';
import { BuyPlanButton } from './BuyPlanButton';
import { BookButton } from './BookButton';
import { CartBookButtons } from './CartBookButtons';
import { FormError } from '../../../../types/errors';

export interface BookButtonContainerProps {
  serviceData: ServiceData;
  isCart?: boolean;
  status: FormStatus;
  shouldShowCollapseForm?: boolean;
  errors: FormError[];
}

const ButtonsContainer: React.FC<BookButtonContainerProps> = ({
  serviceData,
  isCart,
  status,
  shouldShowCollapseForm,
  errors,
}) => {
  // TODO: Support multi services
  const singleSlotServce = mapToArray<SlotService>(serviceData.slotServices)[0];
  const { selectedPaymentOption } = singleSlotServce;

  if (selectedPaymentOption.id === ReservedPaymentOptionIds.BuyAPricingPlan) {
    return (
      <BuyPlanButton
        serviceData={serviceData}
        status={status}
        errors={errors}
      />
    );
  }

  if (isCart) {
    return (
      <CartBookButtons
        serviceData={serviceData}
        shouldShowCollapseForm={shouldShowCollapseForm}
        status={status}
        errors={errors}
      />
    );
  }

  return (
    <BookButton serviceData={serviceData} status={status} errors={errors} />
  );
};

export default ButtonsContainer;
